@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Dark theme colors only */
    --color-primary: #61dafb;      /* Bright blue */
    --color-secondary: #9f7aea;    /* Bright purple */
    --color-background: #0c0c1d;   /* Very dark blue */
    --color-surface: #1c1c3d;      /* Dark blue-purple */
    --color-card: rgba(28, 28, 61, 0.7);
    --color-card-hover: rgba(28, 28, 61, 0.9);
    --color-border: rgba(97, 218, 251, 0.2);
    --color-border-hover: rgba(159, 122, 234, 0.4);
    --color-text-primary: #ffffff;   /* White for main text */
    --color-text-secondary: #9f7aea; /* Purple for secondary text */
    --color-text-muted: #a0aec0;    /* Light gray for muted text */
    --color-backdrop: rgba(12, 12, 29, 0.9);
    --shadow-color: rgba(97, 218, 251, 0.1);
    --card-background: linear-gradient(145deg, rgba(28, 28, 61, 0.7), rgba(28, 28, 61, 0.3));
    --card-shadow: 0 4px 6px -1px rgba(97, 218, 251, 0.1), 0 2px 4px -1px rgba(159, 122, 234, 0.06);
  }
}

/* Base styles */
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: var(--color-background);
  color: var(--color-text-primary);
}

/* Card base styles */
.card {
  background: var(--card-background);
  border: 1px solid var(--color-border);
  box-shadow: var(--card-shadow);
  backdrop-filter: blur(8px);
  transition: all 0.3s ease;
}

.card:hover {
  border-color: var(--color-border-hover);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -1px var(--shadow-color);
}

/* Resume cards specific styling */
.resume-card {
  background: var(--card-background);
  border: 1px solid var(--color-border);
  box-shadow: var(--card-shadow);
  padding: 1.5rem;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.resume-card:hover {
  border-color: var(--color-border-hover);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -1px var(--shadow-color);
}

/* Project cards specific styling */
.project-card {
  background: var(--card-background);
  border: 1px solid var(--color-border);
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
}

.project-card:hover {
  border-color: var(--color-border-hover);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -1px var(--shadow-color);
}

/* Skill cards specific styling */
.skill-card {
  background: var(--card-background);
  border: 1px solid var(--color-border);
  box-shadow: var(--card-shadow);
  transition: all 0.3s ease;
}

.skill-card:hover {
  border-color: var(--color-border-hover);
  transform: translateY(-2px);
  box-shadow: 0 8px 12px -1px var(--shadow-color);
}

/* Text styles */
.text-gradient {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Button styles */
.btn-primary {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  color: white;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

/* Section headers */
.section-header {
  color: var(--color-text-primary);
  font-weight: bold;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

/* Navbar styling */
.navbar {
  background-color: var(--color-backdrop);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid var(--color-border);
  transition: all 0.3s ease;
}

/* Badge styling */
.badge {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary));
  color: white;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
}

/* Icon containers */
.icon-container {
  background: linear-gradient(145deg, var(--color-primary), var(--color-secondary));
  color: white;
  padding: 0.75rem;
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loading screen specific */
.loading-screen {
  background-color: var(--color-background);
}
